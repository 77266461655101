import React, { Component } from "react";
//import REFRESH from "../Assets/Images/PleaseRefresh.gif";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
		this.style = {
			textStyle: {
				fontFamily: "var(--pontano)",
				fontSize: "50px",
				letterSpacing: "5px",
				fontWeight: "lighter",
				textAlign: "center",
			},
		};
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}
	componentDidCatch(err, errInfo) {
		return console.log(err, errInfo);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className={["flexRow", "fullPage", "fadeIn"].join(" ")}>
					<h1 style={this.style.textStyle}>
						SOMETHING'S NOT QUITE RIGHT... <br /> PLEASE REFRESH THE PAGE
					</h1>
				</div>
			);
		}

		return this.props.children;
	}
}
export default ErrorBoundary;
