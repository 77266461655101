import React from "react";
import IG from "../../Assets/images/icons/icons8-instagram-logo-50.png";
import FB from "../../Assets/images/icons/icons8-facebook-circled-50.png";

const Paginator = ({ animate, number, text, color, border }) => {
	const revealAnimation = {
		position: "absolute",
		transform: animate ? "translateX(0)" : "translateX(-100%)",
	};
	const hideElContainer = {
		position: "relative",
		height: "150px",
		width: "150px",
		overflowX: "hidden",
	};
	return (
		<div
			className="flexRow"
			style={{
				paddingLeft: "5%",
				paddingTop: "1%",
				justifyContent: "flex-start",
				alignItems: "flex-start",
				background: color || "#191917",
				maxHeight: "70px",
				width: "100vw",
				position: "absolute",
				bottom: 0,
				borderTop: `.5px solid ${border || "rgba(255, 255, 255, 0.5)"}`,
				transition: "1s ease",
			}}>
			<div style={hideElContainer}>
				<h1
					className="heading"
					style={{
						margin: "0 5px",
						fontSize: "40px",
						...revealAnimation,
					}}>
					{" "}
					{number} <span style={{ color: "var(--red)" }}>-</span>
				</h1>
			</div>
			<div style={hideElContainer}>
				<span
					style={{
						...revealAnimation,
						fontFamily: "var(--pontano)",
						letterSpacing: "1px",
						fontSize: "12px",
						marginTop: "20px",
						transition: "1s ease",
					}}>
					{text}
				</span>
			</div>

			<div
				className="flexRow"
				style={{
					width: "60px",
					justifyContent: "space-evenly",
					margin: "20px 0",
				}}>
				<img style={{ width: "18px", height: "18px" }} src={IG} alt="Insta" />
				<img
					style={{ width: "18px", height: "18px" }}
					src={FB}
					alt="FaceBook"
				/>
			</div>
		</div>
	);
};

export default Paginator;
