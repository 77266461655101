import React from "react";
import * as CSS from "./loaders.module.css";

const FullPageLoader = () => {
	return (
		<div
			className={["clampedFullPage", "flexRow"].join(" ")}
			style={{ backgroundColor: "black", zIndex: "950" }}>
			<svg
				width="137"
				height="130"
				viewBox="0 0 471 464"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					className={[CSS.fadeIn].join(" ")}
					pathLength="1"
					d="M57.5403 55.0231C57.5403 60.8353 52.8297 65.5461 47.0201 65.5461C41.2106 65.5461 36.5 60.8353 36.5 55.0231C36.5 49.2108 41.2106 44.5 47.0201 44.5C52.8297 44.5 57.5403 49.2108 57.5403 55.0231Z"
					stroke="white"
					strokeWidth="5"
				/>
				<path
					className={[CSS.fadeIn].join(" ")}
					pathLength="1"
					d="M267.54 245.023C267.54 250.835 262.83 255.546 257.02 255.546C251.211 255.546 246.5 250.835 246.5 245.023C246.5 239.211 251.211 234.5 257.02 234.5C262.83 234.5 267.54 239.211 267.54 245.023Z"
					stroke="white"
					strokeWidth="5"
				/>
				<path
					className={CSS.drawLogo}
					pathLength="1"
					d="M49.0068 254.5L49.0068 156.5L160.633 156.501L160.633 53.0009L254 53.0009"
					stroke="white"
					strokeWidth="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};

export default FullPageLoader;
