import React, { useRef } from "react";
import * as css from "./marquees.module.css";

import { useScrollTrigger } from "../../Hooks/useScrollTrigger";

const M2 = (props) => {
	const m2 = useRef();
	const { isFullPage } = useScrollTrigger(m2);
	const style = {
		text: {
			position: "absolute",
			top: "0",
			fontFamily: "var(--prata)",
			fontWeight: "lighter",
			fontSize: "20px",
			letterSpacing: "5px",
			whiteSpace: "nowrap",
			transition: "1s linear",
		},
	};

	return (
		<div ref={m2} className={["flexRow", css.wrapper].join(" ")}>
			<h1
				className={isFullPage ? css.marqueeTwo : null}
				style={{
					...style.text,
					fontFamily: "'Work Sans', sans serif",
					fontSize: "20px",
					fontWeight: "bold",
					letterSpacing: "10px",
					color: "#ABA79A",
				}}>
				{" CLUB CULTURE ".repeat(20)}
			</h1>
		</div>
	);
};

export default M2;
