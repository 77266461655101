import React from "react";
import * as CSS from "./header.module.css";

const Burger = (props) => {
	return (
		<div
			onClick={props.toggleMenu}
			className={CSS.burgerContainer}
			style={{
				width: "50px",
				height: "50px",
				top: props.isShowingMenu ? "25%" : "10%",
			}}>
			<svg className={[CSS.svgBurgerContainer].join(" ")}>
				<line
					className={[
						props.isShowingMenu ? null : CSS.burgerGrow,
						CSS.burger,
					].join(" ")}
					x1="0"
					x2="20"
					y1={props.isShowingMenu ? "2" : "20"}
					y2="20"
					stroke="white"
				/>

				<line
					className={[
						props.isShowingMenu ? null : CSS.burgerShrink,
						CSS.burger,
					].join(" ")}
					x1="0"
					x2={props.isShowingMenu ? "20" : "30"}
					y1={props.isShowingMenu ? "20" : "30"}
					y2={props.isShowingMenu ? "2" : "30"}
					stroke="white"
				/>
			</svg>
		</div>
	);
};

export default Burger;
