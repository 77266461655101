import { useState, useEffect } from "react";

export const useScrollTrigger = (ref) => {
	const [isFullPage, setIsFullPage] = useState(false);
	const [isScrollingIntoView, setIsScrollingIntoView] = useState(false);
	const [isScrollingOutOfView, setIsScrollingOutOfView] = useState(false);
	const isInView = isScrollingIntoView || isFullPage;

	useEffect(() => {
		const fullViewObserver = new IntersectionObserver(
			([entry]) => {
				if (entry.intersectionRatio >= 0.9) {
					setIsFullPage(true);
				}
			},
			{
				root: null,
				rootMargin: "0px",
				threshold: 0.9,
			}
		);
		const scrollInObserver = new IntersectionObserver(
			([entry]) => {
				if (entry.intersectionRatio >= 0.4) {
					setIsScrollingIntoView(true);
				}
			},
			{
				root: null,
				rootMargin: "0px",
				threshold: 0.4,
			}
		);

		const scrolloutObserver = new IntersectionObserver(
			([entry]) => {
				if (entry.intersectionRatio < 0.5) {
					setIsScrollingIntoView(false);
					setIsFullPage(false);
					setIsScrollingOutOfView(true);
				}
			},
			{
				root: null,
				rootMargin: "0px",
				threshold: 0.5,
			}
		);

		if (ref.current) {
			fullViewObserver.observe(ref.current);
		}
		if (ref.current) {
			scrollInObserver.observe(ref.current);
		}
		if (ref.current) {
			scrolloutObserver.observe(ref.current);
		}
		return () => {
			fullViewObserver.disconnect();
			scrollInObserver.disconnect();
			scrolloutObserver.disconnect();
		};
	}, []);

	return {
		isFullPage,
		isScrollingIntoView,
		isScrollingOutOfView,
		isInView,
	};
};
