import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useGsapReverse } from "../../Hooks/useGsapReverse";

const AnimatedHeading = ({
	stat,
	x,
	y,
	textColor,
	spanColor,
	italicText,
	firstSpan,
	secondText,
	secondSpan,
	thirdSpan,
	thirdText,
	fourthSpan,
	decorativeLine,
	animate,
	isMobile,
}) => {
	const tl = useRef();
	const italic = useRef();
	const second = useRef();
	const third = useRef();

	useEffect(() => {
		tl.current = gsap
			.timeline()
			.to([italic.current, second.current, third.current], {
				y: 0,
				x: 0,
				delay: 1,
			});
	}, []);

	useGsapReverse(tl, animate);
	const style = {
		text: {
			color: "#F8F8F4",
			fontWeight: "lighter",
			fontSize: "4vw",
			letterSpacing: ".5vw",
			transition: ".3s ease",
		},
		textContainer: {
			position: "absolute",
			overflow: "hidden",
			width: `calc(5vw * 10)`,
			transition: "1s ease",
		},
	};
	return (
		<div
			style={{
				position: stat ? "relative" : "absolute",
				top: y,
				left: x,
				width: "50vw",
				height: "20vh",
				zIndex: "850",
			}}>
			<div
				style={{
					...style.textContainer,
					top: 0,
					left: 0,
				}}>
				<div
					ref={italic}
					style={{
						...style.text,
						transform: "translateX(850px)",
						fontFamily: "var(--prata)",
						fontStyle: "italic",
					}}>
					{italicText}{" "}
				</div>
			</div>
			<div
				style={{
					...style.textContainer,
					top: isMobile ? "50px" : "75px",
					left: isMobile ? "20%" : "30%",
				}}>
				<div
					ref={second}
					style={{
						fontFamily: "var(--pontano)",
						...style.text,
						transform: "translateX(-850px)",
					}}>
					<span style={{ color: "var(--red)" }}>{firstSpan}</span>
					{secondText}{" "}
					<span style={{ color: spanColor || "var(--red)" }}>{secondSpan}</span>
				</div>
			</div>
			<div
				style={{
					...style.textContainer,
					top: isMobile ? "100px" : "145px",
					left: "10%",
				}}>
				<div
					ref={third}
					style={{
						fontFamily: "var(--pontano)",
						...style.text,
						transform: "translateY(200px)",
					}}>
					<span style={{ color: spanColor || "var(--red)" }}>{thirdSpan}</span>{" "}
					{thirdText}
					<span style={{ color: spanColor || "var(--red)" }}>{fourthSpan}</span>
				</div>
			</div>
			{decorativeLine && (
				<div
					style={{
						position: "absolute",
						left: "-5%",
						bottom: 0,
						borderBottom: "1px solid var(--red)",
						width: "15vw",
					}}
				/>
			)}
		</div>
	);
};

export default AnimatedHeading;
