import React from "react";
import { useIsMobile } from "../../Hooks/useIsMobile";

const Arcs = ({ animate, x, y, idx, color, str_0, str_1 }) => {
	const { isMobile } = useIsMobile();
	return (
		<svg
			style={{
				width: isMobile ? "200vw" : "125vw",
				height: isMobile ? "200vh" : "125vh",
				position: "absolute",
				top: y,
				left: x,
				zIndex: idx || -2,
				opacity: animate ? 1 : 0,
				transform: animate ? "scale(1.1)" : "scale(1.5)",
				transition: "1s ease",
			}}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1000 1000">
			<path
				d="M1039.5 565.5C1039.5 821.203 831.765 1028.5 575.5 1028.5C319.235 1028.5 111.5 821.203 111.5 565.5C111.5 309.797 319.235 102.5 575.5 102.5C831.765 102.5 1039.5 309.797 1039.5 565.5Z"
				stroke={color || "#FA4416"}
				strokeOpacity="1"
				strokeWidth={str_1 || "1.5"}
			/>
			<circle
				cx="576"
				cy="576"
				r="574.5"
				stroke="white"
				strokeOpacity=".5"
				strokeWidth={str_0 || "1"}
			/>
		</svg>
	);
};

export default Arcs;
