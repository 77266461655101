import { configure } from "@testing-library/react";
import style from "radium/lib/components/style";
import React from "react";

const BackgroundImage = ({
	col,
	style,
	src,
	children,
	animate,
	x,
	signalLoadEnd,
	isMobile,
}) => {
	const fillViewPort = {
		height: "100vh",
		width: "100vw",
		maxHeight: "100vh",
		maxWidth: "100vw",
	};
	const animateOpacity = {
		opacity: animate && isMobile ? 0.6 : animate ? 0.9 : 0,
	};

	return (
		<div
			className={col ? "flexCol" : "flexRow"}
			style={{
				...fillViewPort,
				position: "relative",
				overflow: "hidden",
				...style,
			}}>
			<img
				onLoad={signalLoadEnd ? signalLoadEnd : null}
				src={src}
				style={{
					...animateOpacity,
					position: "absolute",
					top: 0,
					objectFit: isMobile ? "cover" : null,
					height: isMobile ? "100vh" : "871px",
					width: isMobile ? "100vw" : "968px",
					maxHeight: "871px",
					maxWidth: "968px",
					left: x || "50%",
					transform: `translateX(-50%) ${
						animate ? `scale(1.01)` : `scale(.8)`
					}`,
					transition: "2.5s ease",
					zIndex: -1,
				}}
				alt=""
			/>
			{children}
		</div>
	);
};

export default BackgroundImage;
