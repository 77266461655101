import React, { useRef } from "react";

import { useScrollTrigger } from "../Hooks/useScrollTrigger";
import { useIsMobile } from "../Hooks/useIsMobile";

import Form from "../UI/Form/formController";
import PayPal from "../Assets/images/icons/Paypal.png";
import K from "../Assets/images/logos/KHAOStransWhite.svg";

import M2 from "../Landing/Marquees/m2";

const Footer = () => {
	const footer = useRef();
	const { isInView } = useScrollTrigger(footer);
	const { isMobile } = useIsMobile();

	const style = {
		outerContainer: {
			position: "relative",
			height: "fit-content",
			width: "100vw",
			overflowX: "hidden",
			opacity: isInView ? 1 : 0,
			transition: ".5s ease",
			zIndex: 1,
			background: "black",
		},
		innerContainer: {
			width: "100vw",
			height: isMobile ? "fit-content" : "50vh",
			justifyContent: "space-evenly",
			alignItems: "space-evenly",
			borderTop: "1px solid rgba(255, 255, 255, 0.5)",
			transition: ".5s ease",
			position: "relative",
			bottom: "0",
			left: "0",
		},
		contentContainer: {
			position: "relative",
			padding: "2.5%",
			width: isMobile ? "80%" : "33%",
			maxHeight: isMobile ? "fit-content" : "80%",
			minHeight: isMobile ? null : "80%",
			borderRight: isMobile ? null : "1px solid rgba(255, 255, 255, 0.5)",
			borderBottom: isMobile ? "1px solid rgba(255, 255, 255, 0.5)" : null,
		},
	};
	return (
		<div id="contact" ref={footer} style={style.outerContainer}>
			<div
				className={isMobile ? "flexCol" : "flexRow"}
				style={style.innerContainer}>
				<div className="heading" style={style.contentContainer}>
					CONTACT US
					<Form endpoint="mail" redArr isInView={isInView} />
				</div>
				<div className="heading" style={style.contentContainer}>
					FAN OF OUR WORK?
					<form
						action="https://www.paypal.com/cgi-bin/webscr"
						method="post"
						target="_top">
						<input type="hidden" name="cmd" value="_s-xclick" />
						<input
							type="hidden"
							name="hosted_button_id"
							value="W6Z6CSGUETJLN"
						/>
						<input
							hidden
							type="image"
							src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_LG.gif"
							border="0"
							name="submit"
							title="PayPal - The safer, easier way to pay online!"
							alt="Donate with PayPal button"
						/>
						<img
							hidden
							alt=""
							border="0"
							src="https://www.paypal.com/en_GB/i/scr/pixel.gif"
							width="1"
							height="1"
						/>
						<button
							style={{
								background: "black",
								border: "none",
								cursor: "pointer",
							}}>
							<img
								src={PayPal}
								alt="Donate with PayPal"
								style={{ width: "250px", height: "100px" }}
							/>
						</button>
					</form>
					<small className="smallPontanoText">
						Donations go through our parent company Khaos Europe. 100% of
						donations are invested in the project with a primary focus on our
						artists. <br /> &#9829;
					</small>
				</div>
				<div
					className={["smallPontanoText", "flexCol"].join(" ")}
					style={{
						...style.contentContainer,
						borderRight: "none",
						textAlign: "center",
					}}>
					Club Culture operates under our parent company Khaos Europe and was
					previously The Sunday Art Club. <br />
					<img
						src={K}
						alt="Khaos Europe Logo"
						style={{ width: "75px", height: "100px", margin: "10px" }}
					/>
					<a
						style={{
							color: "var(--red)",
							textDecoration: "none",
						}}
						href="https://www.khaoseurope.com/">
						khaoseurope.com{" "}
					</a>
					<br />
					Registered in England and Wales. <br />
					Registered Company number: 10871374 <br />
					CLUB CULTURE | 2022 | &#174;
				</div>
			</div>
			<M2 />
		</div>
	);
};

export default Footer;
