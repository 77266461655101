import { useEffect, useState } from "react";

export const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState(null);
	const [deviceSize, setDeviceSize] = useState(null);

	const logDeviceSize = () => {
		console.log("Resize event");
		setDeviceSize(window.innerWidth);
	};
	useEffect(() => {
		if (window.innerWidth < window.innerHeight) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [deviceSize]);

	useEffect(() => {
		window.addEventListener("resize", logDeviceSize);
		return () => {
			window.removeEventListener("resize", logDeviceSize);
		};
	}, []);

	return {
		isMobile,
	};
};
