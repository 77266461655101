import React from "react";
import * as css from "./form.module.css";
import RDRRW from "../../Assets/images/icons/redArrow2.svg";
import WRRW from "../../Assets/images/icons/whiteArr.svg";

const Form = ({ isInView, formik, redArr, color, borderColor }) => {
	const formInputStyle = {
		width: isInView ? "100%" : 0,
		borderBottom: `1px solid ${borderColor}` || null,
		color: borderColor || null,
	};

	return (
		<form
			className={["flexCol", css.form].join(" ")}
			style={{ opacity: isInView ? 1 : 0 }}>
			{Object.keys(formik.initialValues).map((i, idx) => {
				return (
					<React.Fragment key={`formItemContainer${idx}`}>
						<label
							className={["smallPontanoText", css.formLabels].join(" ")}
							key={`L5formLabel${idx}`}
							htmlFor={i}
							style={{
								color: color || "white",
								height: isInView ? "20px" : 0,
								opacity: isInView ? 1 : 0,
								fontWeight: "bold",
							}}>
							{i.toUpperCase()}
							{formik.touched[i] && formik.errors[i] && (
								<small
									style={{ marginLeft: "10px" }}
									className="smallPontanoText">
									{" "}
									*{formik.errors[i]}{" "}
								</small>
							)}
						</label>
						{idx === 2 ? (
							<textarea
								className={css.formInputs}
								{...formik.getFieldProps(i)}
								key={`L5formTextArea${idx}`}
								style={{
									...formInputStyle,
									transitionDelay: `calc(${idx}s / 3)`,
									resize: "none",
								}}
							/>
						) : (
							<input
								className={css.formInputs}
								{...formik.getFieldProps(i)}
								key={`L5formInput${idx}`}
								style={{
									...formInputStyle,
									transitionDelay: `calc(${idx}s / 3)`,
								}}
							/>
						)}
					</React.Fragment>
				);
			})}
			<img
				className={css.sendArrow}
				onClick={formik.handleSubmit}
				src={redArr ? RDRRW : WRRW}
				alt="Send Message"
			/>
		</form>
	);
};

export default React.memo(Form);
