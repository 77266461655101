// Import the functions you need from the SDKs you need
import {
	getFirestore,
	collection,
	doc,
	getDoc,
	addDoc,
	getDocs,
	updateDoc,
	arrayUnion,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBhg22p9WTTTdRUyD7SK3GCHajdr9D2PxE",
	authDomain: "clubculture-3129b.firebaseapp.com",
	projectId: "clubculture-3129b",
	storageBucket: "clubculture-3129b.appspot.com",
	messagingSenderId: "109986235966",
	appId: "1:109986235966:web:017e3d39fd2431ce62b36f",
	measurementId: "G-FSNJXZYB84",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();

export const configuredAddDoc = (endpoint, values) => {
	return addDoc(collection(db, endpoint), { values });
};
export const configuredGetRequest = (endpoint, id) => {
	return getDoc(doc(db, endpoint, id));
};
export const configuredGetAll = (endpoint) => {
	return getDocs(collection(db, endpoint));
};

export const configuredUpdateArr = (endpoint, id, event, email) => {
	return updateDoc(doc(db, endpoint, id), {
		[event]: arrayUnion(email),
	});
};
