import React, { useRef, useEffect, Suspense } from "react";
import { HashLink } from "react-router-hash-link";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useJustInTimeLoad } from "../Hooks/useJustInTimeLoad";
import css from "./NL.module.css";

import SCRLL from "../Assets/images/icons/scroll.svg";
import NH1 from "../Assets/images/CC_OPT_IMAGES/L1.png";
import NH2 from "../Assets/newLandingImgs/newH2.png";
import NH3 from "../Assets/newLandingImgs/newH3.png";
import NH6 from "../Assets/newLandingImgs/newH6.png";
import BGVID from "../Assets/video/ccbg01.mp4";
import CARNAL_HELL_TEASER from "../Assets/video/CARNAL_HELL_TEASER.mp4";
import Header from "../Header/header";
import Button from "../UI/Buttons/mainButton";
import Loader from "../UI/Loaders/fullPageLoader";

const NewL4 = React.lazy(() => import("./newAccentPages/newL4"));
const NewL5 = React.lazy(() => import("./newAccentPages/newL5"));

const L5 = React.lazy(() => import("../Landing/L5/l5"));
const L6 = React.lazy(() => import("../Landing/L6/l6Controller"));

const NLC = () => {
	gsap.registerPlugin(ScrollTrigger);
	const transition_01 = useRef();
	const bgVidRef = useRef();
	const transition_02 = useRef();
	const transition_03 = useRef();
	const transition_04 = useRef();
	const transition_05 = useRef();

	const l4SectionRef = useRef();
	const l5SectionRef = useRef();
	const l6SectionRef = useRef();

	const { beginLazyLoad: l4_BeginLazyLoad } = useJustInTimeLoad(l4SectionRef);
	const { beginLazyLoad: l5_BeginLazyLoad } = useJustInTimeLoad(l5SectionRef);
	const { beginLazyLoad: l6_BeginLazyLoad } = useJustInTimeLoad(l6SectionRef);
	const style = {
		section: {
			position: "relative",
			overflowX: "hidden",
			minHeight: "100vh",
			height: "100vh",
		},
	};
	const initStylesConfig = [
		{ class: ".scrollingText", styles: { xPercent: 0 } },
		{ class: `.bg-img`, styles: { scale: 0.8, autoAlpha: 0 } },
		{ class: `.bgVid`, styles: { scale: 0.8, autoAlpha: 0 } },
		{ class: ".H2-text-container", styles: { autoAlpha: 0 } },
		{ class: ".H3-text-container", styles: { autoAlpha: 0 } },
		{ class: ".NL4", styles: { autoAlpha: 0 } },
		{
			class: ".page-number",
			styles: { x: 0, position: "absolute", yPercent: -200 },
		},
	];
	useEffect(() => {
		initStylesConfig.forEach((i) => gsap.set(i.class, i.styles));
	});
	useEffect(() => {
		bgVidRef.current.onended = (e) => {
			console.log(e);
			e.target.currentTime = 0;
			e.target.play();
		};
	}, [bgVidRef.current]);

	const config = {
		trigger: ".main",
		scrub: 1,
		fastScrollEnd: 1000,
	};
	useEffect(() => {
		gsap.to(`.bg-img-${0}`, { scale: 1, autoAlpha: 1 });
		gsap.to(`.page-number-${0}`, { yPercent: 0 });
		gsap.to(".scrollingText", {
			scrollTrigger: {
				trigger: ".main",
				start: "top top",
				end: "bottom bottom",
				scrub: 1,
				//	pin: ".pinnedContainer",
			},
			xPercent: -100,
		});

		transition_01.current = gsap
			.timeline({
				defaults: { transition: "1s ease" },
				scrollTrigger: {
					...config,
					start: "0% top",
					end: "8% top",
				},
			})

			.to(`.page-number-${0}`, { yPercent: 200 })
			.to(".H1-text-up", { yPercent: -100, autoAlpha: 0 })
			.to(".H1-text-right", {
				xPercent: 100,
				autoAlpha: 0,
			})
			.to(".H1-text-down", {
				yPercent: 100,
				autoAlpha: 0,
			})
			.to(".H1-text-container", {
				autoAlpha: 0,
			})
			.to(`.bg-img-${0}`, { scale: 0.8, autoAlpha: 0 })
			.to(`.bg-img-${1}`, { scale: 1, autoAlpha: 1 })
			.to(`.page-number-${1}`, { yPercent: 0 })
			.to(".H2-text-container", { autoAlpha: 1 });
		transition_02.current = gsap
			.timeline({
				defaults: { transition: "1s ease" },
				scrollTrigger: {
					...config,
					start: "10% top",
					end: "20% top",
				},
			})
			.to(`.bg-img-${1}`, { scale: 0.8, autoAlpha: 0 })
			.to(`.page-number-${1}`, { yPercent: 200 })
			.to(".H2-text-up", { yPercent: -100, autoAlpha: 0 })
			.to(".H2-text-right", {
				xPercent: 100,
				autoAlpha: 0,
			})
			.to(".H2-text-down", {
				yPercent: 100,
				autoAlpha: 0,
			})
			.to(".H2-text-container", { autoAlpha: 0 })
			.to(`.bg-img-${2}`, { scale: 1, autoAlpha: 1 })
			.to(`.page-number-${2}`, { yPercent: 0 })
			.to(".H3-text-container", { autoAlpha: 1 })
			.to(".video-container", { autoAlpha: 1, scale: 1 });
		transition_03.current = gsap
			.timeline({
				defaults: { transition: "1s ease" },
				scrollTrigger: {
					...config,
					start: "22% top",
					end: "32% top",
				},
			})
			.to(`.bg-img-${2}`, { scale: 0.8, autoAlpha: 0 })
			.to(`.page-number-${2}`, { yPercent: 200 })
			.to(".H3-body-text", { autoAlpha: 0 })
			.to(".H3-text-up", { yPercent: -100, autoAlpha: 0 })
			.to(".H3-text-right", {
				xPercent: 100,
				autoAlpha: 0,
			})
			.to(".H3-text-down", {
				yPercent: 100,
				autoAlpha: 0,
			})
			.to([".static-page-number", `.page-number-${3}`], {
				yPercent: 0,
			})
			.to(".bgVid", { autoAlpha: 0.6, scale: 1 })
			//	.to(".main", { background: "#ABA79A" })
			.add(() => bgVidRef.current.play())
			.to(".H3-text-container", { autoAlpha: 0 })
			//.to(`.bg-img-${3}`, { autoAlpha: 1 })
			.to(`.NL4`, { autoAlpha: 1 })
			.to(".scrollingText", { color: "white" });

		//	.to(".video-container", { autoAlpha: 1, scale: 1 });
		transition_04.current = gsap
			.timeline({
				defaults: { transition: "1s ease" },
				scrollTrigger: {
					...config,
					start: "40% top",
					end: "42% top",
				},
			})
			.to(".main", { background: "black" })
			.to(".bgVid", {
				//width: "100vw",
				//height: "30vh",
				//top: "35vh",
				//opacity: 0.6,
			})
			.to(`.page-number-${3}`, { yPercent: 200 })

			.to([".static-page-number", `.page-number-${4}`], {
				yPercent: 0,
			});

		transition_05.current = gsap
			.timeline({
				defaults: { transition: "1s ease" },
				scrollTrigger: {
					...config,
					start: "60% top",
					end: "68% top",
				},
			})
			.to(".NL5", { autoAlpha: 0 })
			.to(".bgVid", { autoAlpha: 0 })
			.to(`.bg-img-${3}`, { scale: 1, autoAlpha: 1 })
			.to(`.page-number-${4}`, { yPercent: 200 })
			.to([".static-page-number", `.page-number-${5}`], {
				yPercent: 0,
			});

		return () => ScrollTrigger.getAll().forEach((i) => i.kill());
	}, []);
	const pageNumbers = [1, 2, 3, 4, 5, 6];
	const BGIMGS = [NH1, NH2, NH3, NH6];

	return (
		<main className={["main", css.mainContainer].join(" ")}>
			<Header />
			<div className={["flexRow", css.paginatorContainer].join(" ")}>
				<span
					className="bodyText"
					style={{ fontSize: "12px", transform: "rotate(-90deg)" }}>
					GRASSROOTS ART
				</span>
				<span className={["title", "static-page-number"].join(" ")}>0</span>
				<div className={["flexCol", css.movingDigitWrapper].join(" ")}>
					{pageNumbers.map((i, idx) => {
						return (
							<span
								className={["title", "page-number", `page-number-${idx}`].join(
									" "
								)}>
								{i}
							</span>
						);
					})}
				</div>
				<div
					style={{
						width: "30px",
						height: "20px",
						position: "absolute",
						bottom: 0,
						left: "50%",
						borderBottom: "10px solid var(--red)",
					}}
				/>
			</div>
			<div
				className="bgVid"
				style={{
					width: "100vw",
					height: "50vh",
					objectFit: "cover",
					position: "fixed",
					bottom: 0,
					left: 0,
				}}>
				<div
					style={{
						position: "absolute",
						top: 0,
						width: "100vw",
						height: "50%",
						background:
							"linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%)",
						zIndex: 1,
					}}
				/>
				<video
					ref={bgVidRef}
					style={{
						width: "100%",
						height: "100%",
						objectFit: "cover",
					}}
					type="video/mp4"
					muted
					src={BGVID}
				/>
			</div>
			<section
				className={["fullPage", "flexCol", "pinnedContainer"].join(" ")}
				style={{
					zIndex: 1,
					maxHeight: "100vh",
					position: "fixed",
					top: 0,
					left: 0,
				}}>
				<div
					className={["flexCol", "H1-text-container", css.textContainer].join(
						" "
					)}>
					<div className={["flexCol", css.titleWrapper].join(" ")}>
						<span
							className={["H1-text", "H1-text-up"].join(" ")}
							style={{ fontWeight: "lighter" }}>
							{" "}
							CLUB CULTURE
						</span>
						<span
							className={["H1-text", "H1-text-right"].join(" ")}
							style={{
								alignSelf: "flex-end",
							}}>
							{" "}
							IS A <span style={{ color: "var(--red)" }}>UNIQUE</span>
						</span>
						<span className={["H1-text", "H1-text-down"].join(" ")} style={{}}>
							{" "}
							EXPERIENCE
						</span>
					</div>
					<div
						className="flexRow"
						style={{
							position: "absolute",
							left: "40%",
							top: "170%",
							width: "200px",

							height: "45px",
							transition: "1s ease",
						}}>
						<img
							src={SCRLL}
							style={{
								width: "25px",
								margin: "10px",
							}}
						/>
						<h1
							style={{
								fontFamily: "var(--pontano)",
								fontWeight: "lighter",
								letterSpacing: "3px",
								fontSize: "16px",
							}}>
							SCROLL FOR MORE{" "}
						</h1>
					</div>
				</div>
				<div
					className={["flexCol", "H2-text-container", css.textContainer].join(
						" "
					)}>
					<div className={["flexCol", css.titleWrapper].join(" ")}>
						<span
							className={["H2-text", "H2-text-up"].join(" ")}
							style={{ fontWeight: "lighter" }}>
							{" "}
							WE CREATE
						</span>
						<span
							className={["H2-text", "H2-text-right"].join(" ")}
							style={{
								alignSelf: "flex-end",
							}}>
							{" "}
							ONE OF A KIND
						</span>
						<span className={["H2-text", "H2-text-down"].join(" ")} style={{}}>
							{" "}
							<span style={{ color: "var(--red)" }}>IMMERSIVE </span>
							EVENTS
						</span>{" "}
					</div>
					<div className={["flexCol", css.textWrapper].join(" ")}>
						<p className={css.H2_body}>
							Club Culture events are immersive, disruptive and unique. We
							reignite the power of live performance with a diverse programme of
							multi-disciplinary art and artists, tailored to each space, time
							and audience. <br /> <br />
							You can expect anything and everything from exclusive debuts to
							established and loved performers, ranging from musicians and poets
							to dancers and fire breathers - no two shows are the same.
						</p>

						<HashLink to="/events#top">
							<Button pos="relative" label="FIND AN EVENT" animate />
						</HashLink>
					</div>
				</div>
				<div
					className={["flexCol", "H3-text-container", css.textContainer].join(
						" "
					)}>
					<div className={["flexCol", css.titleWrapper].join(" ")}>
						<span
							className={["H3-text", "H3-text-up"].join(" ")}
							style={{ fontWeight: "lighter" }}>
							{" "}
							AND CURATE
						</span>
						<span
							className={["H3-text", "H3-text-right"].join(" ")}
							style={{
								alignSelf: "flex-end",
							}}>
							{" "}
							EXCLUSIVE
						</span>
						<span className={["H3-text", "H3-text-down"].join(" ")} style={{}}>
							{" "}
							<span style={{ color: "var(--red)" }}>ART </span>
							CONTENT
						</span>{" "}
					</div>
					<div className={["flexCol", css.textWrapper].join(" ")}>
						<p className={[css.H2_body, "H3-body-text"].join(" ")}>
							Bringing a taste of the Club Culture experience in digital form,
							we create pieces that are written, directed and performed by an
							incredible array of talent. We're working on a digital collection
							of film, music, theatre and spotlight interviews that showcase the
							rich grassroots culture happening around us right now.
						</p>
						<HashLink to="/discover#top">
							<Button
								w="275px"
								pos="relative"
								label="WATCH PERFORMANCES"
								animate
							/>
						</HashLink>
					</div>
				</div>

				{BGIMGS.map((i, idx) => {
					return (
						<img
							className={["bg-img", `bg-img-${idx}`, css.bgimg].join(" ")}
							src={i}
							alt=""
						/>
					);
				})}
				<span className={[css.backgroundText, "scrollingText"].join(" ")}>
					CLUB CULTURE CLUB CULTURE
				</span>
			</section>
			<section
				className="fullPage"
				style={{ minHeight: "180vh", maxHeight: "180vh" }}
			/>
			<section className="NL4">
				<NewL4 config={config} />
			</section>
			<section className="NL5">
				<NewL5 config={config} />
			</section>
			<section className="NL6" style={{ zIndex: 2 }}>
				<L6 className="l6" />
			</section>
		</main>
	);
};

export default NLC;
