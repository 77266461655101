import { useEffect } from "react";

export const useGsapReverse = (ref, condition) => {
	useEffect(() => {
		if (ref.current.reversed()) {
			ref.current.play();
		} else {
			ref.current.reverse();
		}
	}, [condition]);
};
