import React from "react";
import { HashLink } from "react-router-hash-link";
import * as CSS from "./header.module.css";

const menu = (props) => {
	const navs = ["HOME", "EVENTS", "DISCOVER"];
	return (
		<div
			className={[
				"flexCol",
				CSS.sideMenu,
				props.isVisible ? CSS.sideMenuVis : CSS.sideMenuHidden,
			].join(" ")}>
			<React.Fragment>
				{navs.map((i, idx) => {
					return (
						<HashLink
							key={`hashLinkSideMenu${i}${idx}`}
							style={{ textDecoration: "none" }}
							to={idx < 3 ? `/${i}#top` : `/home#${i}`}>
							<div
								onClick={props.toggleMenu}
								key={`navBtnSideMenuContainer${i}${idx}`}
								className={CSS.navContainer}
								data-before={i}>
								<h5 key={`navBtnSideMenu${i}${idx}`} className={CSS.navButtons}>
									{i}
								</h5>
							</div>
						</HashLink>
					);
				})}
			</React.Fragment>
		</div>
	);
};

export default menu;
