import { useState, useEffect } from "react";

export const useJustInTimeLoad = (ref) => {
	const [beginLazyLoad, setBeginLazyLoad] = useState(false);
	useEffect(() => {
		const scrollInObserver = new IntersectionObserver(
			([entry]) => {
				if (entry.intersectionRatio >= 0.4) {
					setBeginLazyLoad(true);
				}
			},
			{
				root: null,
				rootMargin: "0px",
				threshold: 0.4,
			}
		);

		if (ref.current) {
			scrollInObserver.observe(ref.current);
		}

		return () => {
			scrollInObserver.disconnect();
		};
	}, []);
	return { beginLazyLoad };
};
