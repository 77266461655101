import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { useGsapReverse } from "../../Hooks/useGsapReverse";
import * as CSS from "./mainButton.module.css";

const MainButton = ({ label, type, click, animate, pos, x, y, w }) => {
	const buttonRef = useRef();
	const tl = useRef();
	useEffect(() => {
		tl.current = gsap.timeline().to(buttonRef.current, {
			opacity: 1,
			delay: 1.5,
		});
	}, []);
	useGsapReverse(tl, animate);
	return (
		<button
			style={{
				opacity: animate ? 1 : 0,
				position: pos,
				top: y,
				left: x,
				width: w,
				zIndex: 5,
			}}
			ref={buttonRef}
			data-label={label}
			className={CSS.button}
			type={type}
			onClick={click}>
			{" "}
			<small className={CSS.buttonLabel}>{label}</small>
		</button>
	);
};

export default MainButton;
