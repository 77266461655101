import React from "react";

const SmallText = ({ animate, textColor, pos, x, y, w, children }) => {
	return (
		<p
			className="smallText"
			style={{
				position: pos,
				top: y,
				left: x,
				width: w,
				color: textColor,
				opacity: animate ? 1 : 0,
			}}>
			{children}
		</p>
	);
};

export default SmallText;
