import React, { useState } from "react";
import { configuredAddDoc } from "../../Firebase/firebase";

import { useFormik } from "formik";
import * as Yup from "yup";
import FormView from "./formView";

const Form = ({ isInView, redArr, endpoint, color, borderColor }) => {
	const [loading, setloading] = useState(false);
	const [success, setsuccess] = useState(false);
	const [error, seterror] = useState(false);

	const yupSchema = Yup.object({
		name: Yup.string().trim("Required").required("Required"),
		email: Yup.string()
			.required("Required")
			.email("Please enter a valid email"),
		message: Yup.string().trim("Required").required("Required"),
	});

	const formik = useFormik({
		initialValues: {
			name: "",
			email: "",
			message: "",
		},
		validationSchema: yupSchema,
		onSubmit: async (values) => {
			try {
				await configuredAddDoc(endpoint, values);
				formik.resetForm();
				setloading(false);
				setsuccess(true);
				setTimeout(() => {
					setsuccess(false);
				}, 1500);
			} catch (err) {
				seterror(true);
				console.error(err);
			}
			console.log(endpoint);
		},
	});

	return (
		<FormView
			borderColor={borderColor}
			color={color}
			isInView={isInView}
			formik={formik}
			redArr={redArr}
			error={error}
			loading={loading}
			success={success}
		/>
	);
};

export default React.memo(Form);
