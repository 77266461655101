import React from "react";

const Image = ({
	w,
	h,
	xr,
	xl,
	yt,
	yb,
	src,
	fit,
	clip,
	animate,
	delay,
	isMobile,
}) => {
	const fillParent = { position: "absolute", width: "100%", height: "100%" };
	return (
		<div
			style={{
				position: "absolute",
				top: yt,
				left: xl,
				right: xr,
				bottom: yb,
				opacity: animate ? 1 : 0,
				transition: "1s ease",
				transitionDelay: delay ? delay : "0.15s",
				width: w,
				height: h,
			}}>
			<img
				alt=""
				src={src}
				style={{
					transform: isMobile ? "scale(0.7)" : null,
					width: w,
					height: h,
					objectFit: fit,
					clipPath: clip ? "ellipse(90% 90% at 10% 20%)" : null,
				}}
			/>

			<div
				style={{
					...fillParent,
					bottom: 0,
					backgroundColor: "#0B0B0B",
					width: animate ? 0 : "100%",
					transitionDuration: ".3s",
					transitionDelay: delay ? delay : "0.15s",
					transitionTimingFunction: "linear",
				}}
			/>
		</div>
	);
};

export default Image;
