import React, { Suspense, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import ErrorBoundary from "./ErrorBoundary/errorBoundary";
import FullPageLoader from "./UI/Loaders/fullPageLoader";
import LandingWrapper from "./Landing/landingWrapper";
import Footer from "./Footer/footer";
import NLC from "./New_Landing/NLC";
import { useIsMobile } from "./Hooks/useIsMobile";
const Events = React.lazy(() => import("./Events/eventsController"));
const Discover = React.lazy(() => import("./Discover/discoverController"));
const preLoader = document.getElementById("preLoadScreen");

function App() {
	useEffect(() => {
		preLoader.remove();
	}, []);
	const isMobile = useIsMobile();
	return (
		<ErrorBoundary>
			<BrowserRouter>
				<Suspense fallback={<FullPageLoader />}>
					<Routes>
						<Route path="/home" element={<NLC />} />
						{/*<Route path="/home" element={<LandingWrapper />} />*/}
						<Route path="/events" element={<Events isMobile={isMobile} />} />
						<Route path="/discover" element={<Discover />} />
						<Route path="/" element={<Navigate replace to="/home" />} />
					</Routes>
				</Suspense>
				<Footer />
			</BrowserRouter>
		</ErrorBoundary>
	);
}

export default App;
